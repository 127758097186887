import { FETCH_COMICS_SUCCESS } from "../../_actions/comics";

const counters = (state = [], action) => {
  switch (action.type) {
    case FETCH_COMICS_SUCCESS: {
      const allCounters = getUniqueCounters(action.response.comicsList);

      return [...allCounters];
    }
    default:
      return state;
  }
};

export const getCounters = (state) => state.counters;

export default counters;

const getUniqueCounters = (comicsList) => {
  const countersList = [];
  comicsList.map(comic => {
    countersList.push(...comic.contadores)
    return comic;
  });

  const uniqueCountersList = [...new Set(countersList)].sort();

  const allCounters = uniqueCountersList.map(counter => {
    let count = 0;
    let comics = [];
    comicsList.map(comic => {
      if (comic.contadores.includes(counter)) {
        count++;
        comics = [...comics, { name: comic.name, id: comic.id }];
      }

      return comic;
    })

    return {
      title: counter,
      count,
      comics
    }
  })
  return [...allCounters];
}