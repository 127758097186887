import { FETCH_GALLERY_SUCCESS } from "../../_actions/comics";

const galleries = (state = {}, action) => {
    switch (action.type) {
        case FETCH_GALLERY_SUCCESS: {
            const allGalleries = getFilteredGalleries(action.response.galleries);

            return allGalleries;
        }
        default:
            return state;
    }
};

export const getGalleries = (state) => state.galleries;

export default galleries;

const getFilteredGalleries = (galleries) => {
    const galleriesList = Object.groupBy(galleries, ({tag}) => tag);
    return galleriesList;
}