import React, { useState, useRef } from 'react';
import TableHeader from '../TableHeader';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Link from '@mui/material/Link';


//Redux
import { getAllCounters } from "../../_reducers/counters";
import { connect } from "react-redux"
import { Typography } from '@mui/material';

const COUNTER_SORTS = [
    {
        id: "name",
        label: "Alfabética",
        active: false
    },
    {
        id: "count",
        label: "Total de Quadrinhos",
        active: true
    }
]

const CountersList = ({ countersList, openModal }) => {
    const isDarkTheme = useTheme().palette.mode === 'dark';
    const [searchText, setSearchText] = useState("");
    const [counterSorts, setCounterSorts] = useState(COUNTER_SORTS);
    const [reverseList, setReverseList] = useState(true);

    const onReverseList = () => {
        setReverseList(!reverseList);
    }

    const onChangeSortMenu = (item) => {
        const newCounterSorts = [...counterSorts].map((sort) => {
            if (sort.id === item) {
                return { ...sort, active: true }
            } else {
                return { ...sort, active: false }
            }
        })
        setCounterSorts([...newCounterSorts])
    }

    const sortCounterList = (list) => {
        const activeSort = counterSorts.find((sort) => sort.active);
        let newCounterList = []

        if (activeSort.id === "name") {
            newCounterList = [...list].sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;

            });
        } else if (activeSort.id === "count") {
            newCounterList = [...list].sort((a, b) => {
                return a.count - b.count;
            });
        } else {
            newCounterList = list;
        }

        return newCounterList;
    }
    //-------- Filter
    const filteredCountersList = countersList.filter(counter => {
        if (counter.title.toLowerCase().includes(searchText.toLowerCase())) {
            return counter
        } else {
            return false;
        }
    });

    const sorteredCountersList = sortCounterList(filteredCountersList);

    if (reverseList) {
        sorteredCountersList.reverse()
    }

    //-------- Filter
    //-------- Pagination
    const ref = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 15;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = sorteredCountersList.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(sorteredCountersList.length / recordsPerPage);
    const numbers = [...Array(nPage + 1).keys()].slice(1);
    const [recordOpen, setRecordOpen] = useState(-1);
    //-------- Pagination

    const onChangeSearchText = (text) => {
        setSearchText(text)
    }

    const handlePageChange = (event, value) => {
        setRecordOpen(-1)
        setCurrentPage(value)
    };

    const RenderCounter = (counter, i) => {
        const isExpanded = i === recordOpen;

        return <Accordion expanded={isExpanded} >
            <AccordionSummary
                onClick={() => setRecordOpen(isExpanded ? -1 : i)}
                expandIcon={<ExpandMoreIcon />}
            >
                <b>{`${counter.title} (${counter.count})`}</b>
            </AccordionSummary>
            <AccordionDetails>
                {counter.comics.map((comic) => {
                    return <div>
                        <Link onClick={() => openModal(comic.id)} style={{cursor: "pointer"}} underline="hover">
                            {comic.name}
                        </Link>
                    </div>
                })}
            </AccordionDetails>
        </Accordion>
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <TableHeader isReverse={reverseList} onReverse={onReverseList} menuList={counterSorts} menuOnChange={onChangeSortMenu} text={searchText} onChangeText={onChangeSearchText} counters={countersList} title="Contadores" onRight searchLabel="Pesquisar contadores..." />
            <div ref={ref} style={{ width: "100%", marginRight: 30, marginBottom: 5, maxHeight: 1000, overflow: "auto", scrollbarColor: isDarkTheme ? "rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0)" : "#D3D1CB rgba(0, 0, 0, 0)" }}>
                {records.map((counter, i) => {
                    return RenderCounter(counter, i)
                })}
            </div>
            <div style={{ flex: 1, alignSelf: 'end', marginRight: 30, marginBottom: 20 }}>
                <Typography variant="caption">
                    {`Total de ${sorteredCountersList.length} contadores`}
                </Typography>
            </div>
            <Pagination variant="outlined" size="large" shape="rounded" color="primary" count={numbers.length} page={currentPage} onChange={handlePageChange} />
        </div>)
}

export default connect(
    state => {
        return {
            countersList: getAllCounters(state),
        };
    },
    {}
)(CountersList);