import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import GalleryList from './GalleryList';

//Redux
import { getAllGalleries } from "../../_reducers/galleries";
import { connect } from "react-redux"
import { Typography } from '@mui/material';

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const GalleriesPage = ({ galleriesList, openModal }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example">
                        {Object.keys(galleriesList).map((groupedGallery, i) => {
                            return <Tab label={groupedGallery} {...a11yProps(i)} />
                        })}
                    </Tabs>
                </Box>

                {Object.keys(galleriesList).map((groupedGallery, i) => {
                    return <CustomTabPanel key={i} value={value} index={i}>
                        <div style={{ flex: 1, alignSelf: 'end', marginRight: 30, marginBottom: 20 }}>
                            <Typography variant="caption">
                                {`Total de ${galleriesList[groupedGallery].length} registros`}
                            </Typography>
                        </div>
                        <GalleryList openModal={openModal} gallery={galleriesList[groupedGallery]} />
                    </CustomTabPanel>
                })}
            </div>
        </div>
    );
}

export default connect(
    state => {
        return {
            galleriesList: getAllGalleries(state),
        };
    },
    {}
)(GalleriesPage);