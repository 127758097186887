import * as React from 'react';
import ComicsPage from "./ComicsPage/ComicsPage.js";
import CountersPage from "./CountersPage/CountersPage.js";
import GalleriesPage from "./GalleriesPage/GalleriesPage.js";
import AboutPage from "./AboutPage/AboutPage.js";
import LoadingImage from "../assets/LOADCICLO.gif"

//Redux
import { getIsFetching } from "../_reducers/comics/isFetching";
import { getComics } from '../_reducers/comics/index.js';
import { connect } from "react-redux"
import useWindowDimensions from '../utils/windowDimension.js';

const RenderLoadingPage = () => {

  const { height } = useWindowDimensions()

  return <div style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }}>

    <img style={{ maxHeight: height - 290, objectFit: "contain" }} src={LoadingImage} alt={"LQN Enciclopédia"} />
  </div>
}

const ContentPage = ({ isFetching, activePage, changeOpenModal, comics }) => {

  return (<div style={{ display: "flex", marginBottom: isFetching ? 0 : 50 }}>
    {activePage === "sobre" ? <AboutPage /> : isFetching && comics.length === 0 ? RenderLoadingPage() :
      activePage === "quadrinhos" ? <ComicsPage openModal={changeOpenModal} /> :
        activePage === "contadores" ? <CountersPage openModal={changeOpenModal} /> :
          activePage === "galeria" ? <GalleriesPage openModal={changeOpenModal} /> : "404"}
  </div>);
}

export default connect(
  (state) => {
    return {
      isFetching: getIsFetching(state.comics),
      comics: getComics(state)
    };
  },
  {}
)(ContentPage);