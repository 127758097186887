import { FETCH_AUTHORS_SUCCESS } from "../../_actions/comics";

const genAuthors = (state = [], action) => {
    switch (action.type) {
        case FETCH_AUTHORS_SUCCESS: {
            const genAuthors = getGenAuthorsData(action.response.authors);

            return genAuthors;
        }
        default:
            return state;
    }
};

export const getGenAuthors = (state) => state.genAuthors;

export default genAuthors;

const getGenAuthorsData = (authorsList) => {
    const readedAuthors = authorsList.filter((author) => {
        return author.status.includes("Lendo")
    })

    const mAuthors = authorsList.filter((author) => {
        return author.status.includes("Lendo") && author.gen === "♂️"
    })

    const fAuthors = authorsList.filter((author) => {
        return author.status.includes("Lendo") && author.gen === "♀️"
    })

    const nbAuthors = authorsList.filter((author) => {
        return author.status.includes("Lendo") && author.gen === "🜬"
    })

    return [
        { id: 0, value: readedAuthors.length, label: 'Total de Autores Lidos' },
        { id: 1, value: mAuthors.length, label: '♂️' },
        { id: 2, value: fAuthors.length, label: '♀️' },
        { id: 3, value: nbAuthors.length, label: '🜬' },
    ];
}