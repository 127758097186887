import { normalize } from "normalizr";
import * as comicApi from "../../utils/api/comic";
import { arrayOfComics, arrayOfGalleries, arrayOfAuthors } from "../schema";

export const FETCH_COMICS_REQUEST = "FETCH_COMICS_REQUEST";
export const FETCH_COMICS_SUCCESS = "FETCH_COMICS_SUCCESS";
export const FETCH_GALLERY_SUCCESS = "FETCH_GALLERY_SUCCESS";
export const FETCH_AUTHORS_SUCCESS = "FETCH_AUTHORS_SUCCESS";
export const FETCH_COMICS_ERROR = "FETCH_COMICS_ERROR";

export const fetchComics = () => async dispatch => {
  dispatch(fetchComicsRequest());
  const response = await comicApi.fetchAll().catch(error => {
    dispatch(fetchComicsError(error));
  });

  if (response) {
    const normalizedData = normalize(response.comics, arrayOfComics);
    dispatch(fetchComicsSuccess({...normalizedData, comicsList: response.comics}));
  }

  if (response) {
    const normalizedData = normalize(response.files, arrayOfGalleries);
    dispatch(fetchGallerySuccess({...normalizedData, galleries: response.files}));
  }

  if (response) {
    const normalizedData = normalize(response.authors, arrayOfAuthors);
    dispatch(fetchAuthorsSuccess({...normalizedData, authors: response.authors}));
  }
};

export const fetchComicsRequest = () => ({
  type: FETCH_COMICS_REQUEST
});

export const fetchComicsSuccess = response => ({
  type: FETCH_COMICS_SUCCESS,
  response
});

export const fetchComicsError = error => ({
  type: FETCH_COMICS_ERROR,
  error
});

export const fetchGallerySuccess = response => ({
  type: FETCH_GALLERY_SUCCESS,
  response
});

export const fetchAuthorsSuccess = response => ({
  type: FETCH_AUTHORS_SUCCESS,
  response
});