import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { colorConverter } from "../../utils/colorConverter"
import { convertDate } from "../../utils/formatDate"
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useDraggable } from "react-use-draggable-scroll";
import ComicCardAuthor from './ComicCardAuthor';

import EncicloLoading from "../../assets/LOADING.png"

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const RenderGeneros = (generos) => {
    const ref = useRef();
    const { events } = useDraggable(ref);

    return (
        <Stack direction="row" spacing={1} style={{ marginLeft: -10, overflow: "hidden", cursor: "grab" }} {...events} ref={ref}>
            {generos.map(gen => {
                return <Chip key={gen.name} label={gen.name} size="small" color={colorConverter(gen.color)} style={{ opacity: 0.7, marginLeft: 5, marginBottom: 3, cursor: "grab", userSelect: "none" }} />
            })}
        </Stack>
    );
}

const RenderCounters = (contadores) => {
    const ref = useRef();
    const { events } = useDraggable(ref);

    return (
        <Stack direction="row" spacing={1} style={{ marginLeft: -10, overflow: "hidden", cursor: "grab" }} {...events} ref={ref}>
            {contadores.map(contador => {
                return <Chip key={contador} label={contador} size="small" style={{ opacity: 0.7, marginLeft: 5, marginBottom: 3, cursor: "grab", userSelect: "none" }} />
            })}
        </Stack>
    );
}

const ComicCard = ({ comic, openModal, isLoading, loadComics }) => {
    const [errorImage, setErrorImage] = useState(false)
    const prevAmount = usePrevious({ isLoading });

    const img = new Image();
    img.src = comic.image;


    useEffect(() => {
        if (prevAmount?.isLoading === true && isLoading === false) {
            setErrorImage(false)
        }
    }, [prevAmount?.isLoading, isLoading])


    return (
        <Card sx={{ width: 250, margin: 1, display: "flex", flexDirection: "column" }}>
            <CardMedia
                onClick={() => {
                    openModal(comic.id)
                }}
                style={{ cursor: "pointer" }}
                component="img"
                height={100}
                image={errorImage ? EncicloLoading : img.src}
                onError={e => {
                    setErrorImage(true)
                    if (!isLoading) {
                        loadComics()
                    }
                }}
                alt={comic.name}
            />
            {errorImage && <LinearProgress />}
            <CardContent style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <div>
                    <Typography variant="h6" fontSize={15} fontWeight={"bold"} >
                        {comic.name}
                    </Typography>
                    <Typography variant="body2" fontSize={12} gutterBottom>
                        {convertDate(comic.data_leitura)}
                    </Typography>
                    {RenderGeneros(comic.genero)}
                </div>
                <div style={{ flex: 1 }}>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Typography variant="body2" fontSize={12} gutterBottom>
                        Capítulos lidos: <b>{comic.caps_lidos}/{comic.total_caps}</b>
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress variant="determinate" color={comic.porcentagem_leitura === 100 ? "success" : comic.porcentagem_leitura > 30 ? "warning" : "error"} value={comic.porcentagem_leitura} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <Typography fontSize={12} variant="body2">{`${Math.round(
                                comic.porcentagem_leitura,
                            )}%`}</Typography>
                        </Box>
                    </Box>

                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: 10
                    }}>
                        <Button size='small' endIcon={<OpenInNewIcon />}
                            onClick={() => {
                                if (comic.link_leitura) {
                                    window.open(comic.link_leitura, '_blank');
                                }
                            }}>
                            Leia agora
                        </Button>
                    </div>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    {comic.contadores.length > 0 &&
                        <div>
                            <Typography variant="body2" fontSize={12} gutterBottom>

                                Contadores <b>{`(${comic.contadores.length})`}</b>
                            </Typography>
                            {RenderCounters(comic.contadores)}
                            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                        </div>}
                </div>
            </CardContent>
            {comic.autores.length > 1 &&
                <div>
                    <Typography
                        style={{ color: "#E54C4C", cursor: "pointer", fontSize: 12, textAlign: "end", marginRight: 15 }}
                        onClick={() => {
                            openModal(comic.id)
                        }}>
                        Ver mais {comic.autores.length - 1} {comic.autores.length - 1 === 1 ? "autor" : "autores"}
                    </Typography>
                </div>}
            <CardActions style={{ display: "flex", alignItems: "center" }}>
                <ComicCardAuthor author_id={comic.autores[0]} link_compra={comic.link_compra} />
                {/* {comic.autores.map((author) => {
                     return <ComicCardAuthor author_id={author} link_compra={comic.link_compra}/>
                })} */}
            </CardActions>
        </Card>
    );
}

export default ComicCard;