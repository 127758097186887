import { FETCH_AUTHORS_SUCCESS } from "../../_actions/comics";

const etnAuthors = (state = [], action) => {
    switch (action.type) {
        case FETCH_AUTHORS_SUCCESS: {
            const etnAuthors = getEtnAuthorsData(action.response.authors);

            return etnAuthors;
        }
        default:
            return state;
    }
};

export const getEtnAuthors = (state) => state.etnAuthors;

export default etnAuthors;

const getEtnAuthorsData = (authorsList) => {
    const unknowAuthors = authorsList.filter((author) => {
        return author.status.includes("Lendo") && author.etnia === "❓"
    })

    const wAuthors = authorsList.filter((author) => {
        return author.status.includes("Lendo") && author.etnia === "🤍"
    })

    const bAuthors = authorsList.filter((author) => {
        return author.status.includes("Lendo") && author.etnia === "🤎"
    })

    const aAuthors = authorsList.filter((author) => {
        return author.status.includes("Lendo") && author.etnia === "💛"
    })

    const iAuthors = authorsList.filter((author) => {
        return author.status.includes("Lendo") && author.etnia === "❤️"
    })
    return [
        { id: 0, value: unknowAuthors.length, label: '❓ Desconhecido' },
        { id: 1, value: wAuthors.length, label: '🤍 Branco' },
        { id: 2, value: bAuthors.length, label: '🤎 Afrodescendente' },
        { id: 3, value: aAuthors.length, label: '💛 Asiático' },
        { id: 4, value: iAuthors.length, label: '❤️ Indígena' },
    ];
}