import React from 'react';
// import HeaderImage from "../assets/header.png"
import HeaderImage from "../assets/HEADER 1.png"

export const Header = () => {

    return (<div style={{
        display: "flex",
        background: "linear-gradient(0deg, rgba(229,76,76,1) 0%, rgba(116,18,18,0) 66%)",
        justifyContent: "center"
    }}>
        <img style={{
        maxHeight: 250}} src={HeaderImage} alt={"LQN Enciclopédia"}/>
    </div>)
}