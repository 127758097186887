import {
  FETCH_COMICS_REQUEST,
  FETCH_COMICS_SUCCESS,
  FETCH_COMICS_ERROR
} from "../../_actions/comics";

const fetchSuccess = (state = false, action) => {
  switch (action.type) {
    case FETCH_COMICS_SUCCESS:
      return true;
    case FETCH_COMICS_REQUEST:
    case FETCH_COMICS_ERROR:
      return false;
    default:
      return state;
  }
};

export const getFetchSuccess = state => state.fetchSuccess;

export default fetchSuccess;
