import axios from 'axios'

export const getAllFromNotion = async () => {

    try {
        //const response = await axios.get(`http://localhost:8000/comics`, {}, {});
        const response = await axios.get(`https://enciclopedialqn-api-3cb0886353e9.herokuapp.com/comics`, {}, {});

        return response.data;
    } catch (err) {
        console.log("Erro ao chamar notion: ", err)
        return err;
    }
}
