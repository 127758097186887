import * as React from 'react';
import CountersList from "./CountersList"
import ChartsPage from "./ChartsPage"

const ComicsPage = ({openModal}) => {

    return (
        <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
            <div style={{ flex: 2 }}>
                <ChartsPage />
            </div>
            <div style={{ flex: 1, marginRight: 20 }}>
                <CountersList openModal={openModal} />
            </div>
        </div>
    );
}

export default ComicsPage;