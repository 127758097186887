import React from "react"
import { Typography } from '@mui/material';

const ButtonComponent = ({image, imageText, buttonColor, buttonText, textColor}) => {

    return (<div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: buttonColor, borderRadius: 25, padding: "0px 20px", marginLeft: -100, marginTop: 20 }}>
            <img style={{ maxHeight: 50 }} src={image} alt={imageText} />
            <Typography fontWeight={"bold"} color={textColor}>
                {buttonText}
            </Typography>
        </div>
    </div>)
}

export default ButtonComponent;