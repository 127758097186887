import './App.css';
import './pagination.css';
import React, { useState, useEffect } from 'react';
import { Header } from "./components/Header.js";
import ComicModal from "./components/ComicModal.js";
import { MenuBar } from "./components/MenuBar.js";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import CssBaseline from "@mui/material/CssBaseline";
import ContentPage from './components/ContentPage.js';

import IconButton from '@mui/material/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { getIsUserTheme } from './utils/userTheme.js';

const MENU_BUTTONS = [
  { title: "Quadrinhos Lidos", active: true, id: "quadrinhos" },
  { title: "Estatísticas", active: false, id: "contadores" },
  { title: "Galeria", active: false, id: "galeria" },
  { title: "Sobre", active: false, id: "sobre" }
]



const App = () => {
  const [themeMode, setThemeMode] = useState("light");
  const [menuButtons, setMenuButtons] = useState(MENU_BUTTONS);
  const [openModal, setOpenModal] = useState(false)
  const [comicModalId, setComicModalId] = useState("")

  useEffect(() => {
    const userTheme = getIsUserTheme();
    setThemeMode(userTheme)
  }, []);

  const changeActiveMenu = (menuId) => {
    const newMenuButtons = [...menuButtons].map((mb) => {
      return { ...mb, active: mb.id === menuId ? true : false }
    })

    setMenuButtons([...newMenuButtons]);
  }

  const changeOpenModal = (comic_id) => {
    setComicModalId(comic_id)
    setOpenModal(true)
  }

  const closeModal = () => {
    setComicModalId("")
    setOpenModal(false)
  }

  const getActiveMenu = () => {
    return menuButtons.find((mb) => mb.active)
  }

  var theme = createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: "#E54C4C",
      },
      secondary: {
        main: purple[300],
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ position: "absolute", right: 50, top: 20 }}>
        <IconButton onClick={() => {
          if (themeMode === "dark") {
            setThemeMode("light")
            localStorage.setItem('themeMode', "light");
          } else {
            setThemeMode("dark")
            localStorage.setItem('themeMode', "dark");
          }
        }}>
          {themeMode === "light" ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>
      </div>
      <div style={{ backgroundColor: theme.palette.background.paper }}>
        {openModal && <ComicModal open={openModal} handleClose={closeModal} comic_id={comicModalId} />}
        <Header />
        <div style={{
          display: "flex", flexDirection: "row", justifyContent: "center",
          background: "linear-gradient(0deg, rgba(116,18,18,1) 0%, rgba(229,76,76,1) 50%)",
          color: "white",
        }}>
          <MenuBar menuButtons={menuButtons} changeActiveMenu={changeActiveMenu} />
        </div>
        <ContentPage activePage={getActiveMenu().id} changeOpenModal={changeOpenModal} />
      </div>
    </ThemeProvider>
  );
}

export default App;