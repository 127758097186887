import * as React from 'react';
import { Typography } from '@mui/material';
import StaffDuhImage from "../../assets/STAFFDUH.png"

const TwitchBanner = () => {
    return (
        <div style={{ cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", background: "linear-gradient(270deg, #6C2498 0%, rgba(0,0,0,0) 50%)" }}
            onClick={() => {
                window.open("https://twitch.tv/bernardolqn", '_blank');
            }}
        >
            <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", maxWidth: 600 }}>
                <img style={{ maxHeight: 200 }} src={StaffDuhImage} alt={"LQN Enciclopédia"} />
                <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    <Typography color={"#6C2498"} fontSize={30} fontWeight={"bold"}>
                        Siga nossas lives!
                    </Typography>
                    <Typography>
                        Lendo em <b>twitch.tv/bernardolqn</b> todas as <b>Quintas</b> e <b>Domingos</b> a partir das <b>21:00hrs</b>.
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default TwitchBanner;