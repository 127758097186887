import { combineReducers } from "redux";
import isFetching, * as fromIsFetching from "./isFetching";
import fetchError, * as fromFetchError from "./fetchError";
import ids, * as fromIds from "./ids";
import byId, * as fromById from "./byId";
import fetchSuccess, * as fromFetchSuccess from "./fetchSuccess";

const getLocalState = state => state.comics;

export default combineReducers({
  isFetching,
  fetchError,
  ids,
  byId,
  fetchSuccess
});

export const getIsFetching = state =>
  fromIsFetching.getIsFetching(getLocalState(state));

export const getFetchError = state =>
  fromFetchError.getFetchError(getLocalState(state));

export const getFetchSuccess = state =>
  fromFetchSuccess.getFetchSuccess(getLocalState(state));

export const getComicById = (state, id) =>
  fromById.getComic(getLocalState(state), id);

export const getComics = state => {
const localState = getLocalState(state);
const ids = fromIds.getIds(localState);
if (!ids) {
  return []
}
return ids.map(id => fromById.getComic(localState, id));
};
