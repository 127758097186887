import { FETCH_AUTHORS_SUCCESS } from "../../_actions/comics";

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_AUTHORS_SUCCESS:
      return {
        ...state,
        ...action.response.entities.author
      };
    default:
      return state;
  }
};

export const getAuthor = (state, id) => {
  return state.byId[id]
};

export default byId;
