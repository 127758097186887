export const getIsUserTheme = () => {
  var usersTheme = "light";
  const lastSaveDarkTheme = localStorage.getItem('themeMode');
  if (lastSaveDarkTheme === null ) {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    const userSystemDarkTheme = darkThemeMq.matches;
    if (userSystemDarkTheme) {
      localStorage.setItem('themeMode', 'dark');
      usersTheme = 'dark';
    } else {
      localStorage.setItem('themeMode', "light");
      usersTheme = "light";
    }
  } else {
    usersTheme = lastSaveDarkTheme;
  }

  return usersTheme;
}