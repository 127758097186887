export const convertDate = (date) => {
    const objectDate = new Date(date);

    let day = objectDate.getDate();

    let month = objectDate.getMonth();

    let year = objectDate.getFullYear();



    let format2 = `${day < 10 ? "0" : ""}${day}/${month < 10 ? "0" : ""}${month + 1}/${year}`

    return format2;
}