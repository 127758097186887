import React from "react"
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Menu from '@mui/material/Menu';
import SortIcon from '@mui/icons-material/Sort';
import Tooltip from '@mui/material/Tooltip';

//autocomplete
import Checkbox from '@mui/material/Checkbox';
import { Badge, Typography } from "@mui/material";

const TableHeader = ({ text, onChangeText, title, onRight, menuList, menuOnChange, isReverse, onReverse }) => {
    const searchTextRef = React.useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div style={{ display: "flex", width: "100%", justifyContent: "space-between", padding: `10px ${onRight ? "30px 10px 0px" : "30px"}` }}>
        <div>
            <Typography variant="h6" fontSize={25} fontWeight={"bold"}>
                {title}
            </Typography>
        </div>
        {/* <Autocomplete
                multiple
                size='small'
                id="checkboxes-tags-demo"
                options={counters || []}
                disableCloseOnSelect
                getOptionLabel={(option) => option.title}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {`${option.title} (${option.count})`}
                    </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                    <TextField {...params} label="Checkboxes" placeholder="Favorites" />
                )}
            /> */}
        <div>
            <TextField
                sx={{
                    width: text ? 200 : 0,
                    '&:focus-within': {
                        width: 200,
                    },
                    transition: 'width 0.5s',
                }}
                label={""}
                variant="standard"
                size='small'
                value={text}
                onChange={(event) => {
                    onChangeText(event.target.value)
                }}
                inputRef={searchTextRef}
            />
            <Tooltip title="Pesquisar" placement="top-start">
                <IconButton onClick={() => [
                    searchTextRef.current.focus()
                ]}>
                    <SearchIcon />
                </IconButton>
            </Tooltip>
            {/* Menu */}
            <Tooltip title="Inverter ordem" placement="top-start">
                <IconButton
                    onClick={onReverse} >
                    <Badge variant={isReverse ? "dot" : ""} color="primary">
                        <ImportExportIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Tooltip title="Ordenar por" placement="top-start">
                <IconButton
                    onClick={handleClick} >
                    <SortIcon />
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Typography variant="subtitle1" style={{ marginLeft: 10, fontWeight: "bold" }}> Ordenação</Typography>
                {menuList.map((sort) => {
                    return <div key={sort.label} onClick={() => { menuOnChange(sort.id) }} style={{ display: "flex", flexDirection: "row", cursor: "pointer", alignItems: "center", paddingRight: 10 }} >
                        <Checkbox checked={sort.active} />
                        <Typography style={{ color: sort.active ? "tomato" : "" }}>{sort.label}</Typography>
                    </div>
                })}
            </Menu>
            {/* Menu */}
        </div >
    </div >
}

export default TableHeader