import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import GalleryItem from "./GalleryItem"


const GalleryList = ({ gallery, openModal }) => {
    console.log("Gallery", gallery)
    return (
        <Box sx={{}}>
            <ImageList variant="masonry" cols={6} gap={8}>
                {gallery.map((item) => (
                    <GalleryItem key={item.id} openModal={openModal} item={item} comic_id={item.comic_id} />
                ))}
            </ImageList>
        </Box>
    );
}

export default GalleryList;