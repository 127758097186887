import {
  FETCH_COMICS_REQUEST,
  FETCH_COMICS_SUCCESS,
  FETCH_COMICS_ERROR
} from "../../_actions/comics";

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_COMICS_REQUEST:
      return true;
    case FETCH_COMICS_SUCCESS:
    case FETCH_COMICS_ERROR:
      return false;
    default:
      return state;
  }
};

export const getIsFetching = (state, test) => {
  return state?.isFetching
};

export default isFetching;
