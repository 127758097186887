import { combineReducers } from "redux";
import galleries, * as fromGalleries from "./galleries";

const getLocalState = state => state.galleries;

export default combineReducers({
  galleries,
});

export const getAllGalleries = state =>
  fromGalleries.getGalleries(getLocalState(state));
