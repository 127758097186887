import React, { useState, useEffect, useRef } from 'react';
import ComicCard from "./ComicCard"
import Pagination from '@mui/material/Pagination';
import TableHeader from '../TableHeader';
import { Typography } from '@mui/material';

//Redux
import { fetchComics } from "../../_actions/comics";
import {
    getComics, getIsFetching,
} from "../../_reducers/comics";
import { getAllCounters } from "../../_reducers/counters";
import { connect } from "react-redux"

const COMIC_SORTS = [
    {
        id: "date",
        label: "Data de Leitura",
        active: true
    },
    {
        id: "name",
        label: "Alfabética",
        active: false
    },
    {
        id: "read",
        label: "Porcentagem de Leitura",
        active: false
    }
]

const ComicsList = ({ fetchComics, isFetchingComics, comicsList, countersList, openModal }) => {
    const ref = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [comicSorts, setComicSorts] = useState(COMIC_SORTS);
    const [reverseList, setReverseList] = useState(true);

    const onReverseList = () => {
        setReverseList(!reverseList);
    }

    const onChangeSortMenu = (item) => {
        const newcomicSorts = [...comicSorts].map((sort) => {
            if (sort.id === item) {
                return { ...sort, active: true }
            } else {
                return { ...sort, active: false }
            }
        })
        setComicSorts([...newcomicSorts])
    }
    const sortComicList = (list) => {
        const activeSort = comicSorts.find((sort) => sort.active);
        let newComicList = []

        if (activeSort.id === "name") {
            newComicList = [...list].sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;

            });
        } else if (activeSort.id === "read") {
            newComicList = [...list].sort((a, b) => {
                return a.porcentagem_leitura - b.porcentagem_leitura;
            });
        } else if (activeSort.id === "date") {
            newComicList = [...list].sort((a, b) => {
                var firstDate = new Date(a.data_leitura);
                var secondDate = new Date(b.data_leitura);
                return firstDate - secondDate
            })
        } else {
            newComicList = list;
        }

        return newComicList;
    }

    //-------- Filter
    const filteredComicsList = comicsList.filter(comic => {
        if (comic.name.toLowerCase().includes(searchText.toLowerCase())) {
            return comic
        }
        for (let index = 0; index < comic.genero.length; index++) {
            if (comic.genero[index].name.toLowerCase().includes(searchText.toLowerCase())) {
                return comic
            }
        }
        for (let index = 0; index < comic.autor.length; index++) {
            if (comic.autor[index].toLowerCase().includes(searchText.toLowerCase())) {
                return comic
            }
        }
    })

    const sorteredComicsList = sortComicList(filteredComicsList);

    if (reverseList) {
        sorteredComicsList.reverse()
    }


    //-------- Filter
    //-------- Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = sorteredComicsList.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(sorteredComicsList.length / recordsPerPage);
    const numbers = [...Array(nPage + 1).keys()].slice(1);
    //-------- Pagination

    useEffect(() => {
        if (comicsList.length === 0) {
            getComicsList();
        }
    })

    const handleChange = (event, value) => {
        setCurrentPage(value)
    };

    const getComicsList = async () => {
        fetchComics();
    }

    const onChangeSearchText = (text) => {
        setSearchText(text)
    }

    return (<div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        {<TableHeader isReverse={reverseList} onReverse={onReverseList} menuList={comicSorts} menuOnChange={onChangeSortMenu} text={searchText} onChangeText={onChangeSearchText} counters={countersList} title="Quadrinhos Lidos" searchLabel="Pesquisar quadrinhos..." />}

        <div style={{ display: "flex", flexFlow: "wrap", justifyContent: "space-between", marginBottom: 5, padding: "0px 20px" }} ref={ref}>
            {records && records.map(((item, index) => (
                <ComicCard loadComics={getComicsList} isLoading={isFetchingComics} openModal={openModal} comic={item} key={item.id} />
            )))
            }
        </div>
        <div style={{ flex: 1, alignSelf: 'end', marginRight: 30, marginBottom: 20 }}>
            <Typography variant="caption">
                {`Total de ${sorteredComicsList.length} quadrinhos`}
            </Typography>
        </div>
        {records && <Pagination variant="outlined" size="large" shape="rounded" color="primary" count={numbers.length} page={currentPage} onChange={handleChange} />}
    </div>)
}

export default connect(
    state => {
        return {
            isFetchingComics: getIsFetching(state),
            comicsList: getComics(state),
            countersList: getAllCounters(state)
        };
    },
    {
        fetchComics,
    }
)(ComicsList);
