import {
    FETCH_COMICS_REQUEST,
    FETCH_COMICS_SUCCESS,
    FETCH_COMICS_ERROR
  } from "../../_actions/comics";
  
  const fetchError = (state = null, action) => {
    switch (action.type) {
      case FETCH_COMICS_REQUEST:
      case FETCH_COMICS_SUCCESS:
        return null;
      case FETCH_COMICS_ERROR:
        return action.error;
      default:
        return state;
    }
  };
  
  export const getFetchError = state => state.fetchError;
  
  export default fetchError;
  