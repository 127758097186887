export const colorConverter = (color) => {
    switch (color) {
        case "grey":
            return "default"
        case "brown":
            return "warning"
        case "orange":
            return "warning"
        case "yellow":
            return "warning"
        case "green":
            return "success"
        case "blue":
            return "info"
        case "purple":
            return "secondary"
        case "pink":
            return "secondary"
        case "red":
            return "error"
        default:
            return "default"
    }

}