import * as React from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { Typography } from '@mui/material';

//Redux
import { getReadedComics, getGenAuthors, getEtnAuthors } from "../../_reducers/counters";
import ChartEnciclo from "../../assets/lendinho.png"
import { connect } from "react-redux"
import { useTheme } from '@emotion/react';

const colors = ["#4daf4a", "#377eb8", "#e41a1c", "#984ea3", "#ff7f00", "#ffff33", "#a65628", "#f781bf", "#999999"]
const colorsEtn = ["#993333", "#C8CFE4", "#6B5745", "#FFA500", "#E54C4C"]

const ChartsPage = ({ readedComics, genAuthors, etnAuthors }) => {
    const theme = useTheme();

    const readedComicsGroupData = [readedComics[0]];
    const readedComicsData = [readedComics[1], readedComics[2]]

    const genAuthorsGroupData = [genAuthors[0]]
    const genAuthorsData = [genAuthors[1], genAuthors[2], genAuthors[3]]


    const seriesComics = [
        {
            innerRadius: 0,
            outerRadius: 100,
            id: 'series-1',
            arcLabel: (item) => `${item.value}`,
            data: readedComicsGroupData.map((data) => { return { ...data, color: colors[data.id] } }),

        },
        {
            innerRadius: 115,
            outerRadius: 145,
            id: 'series-2',
            arcLabel: (item) => `${item.value}`,
            data: readedComicsData.map((data) => { return { ...data, color: colors[data.id] } }),
        },
    ];

    const seriesGenAuthors = [
        {
            innerRadius: 0,
            outerRadius: 60,
            id: 'series-1',
            arcLabel: (item) => `${item.value}`,
            data: genAuthorsGroupData.map((data) => { return { ...data, color: colors[data.id] } }),

        },
        {
            innerRadius: 75,
            outerRadius: 105,
            id: 'series-2',
            arcLabel: (item) => `${item.value}`,
            data: genAuthorsData.map((data) => { return { ...data, color: colors[data.id] } }),
            
        },
    ]

    return (
        <div color={"white"} style={{ display: "flex", flexDirection: "column" }}>
            <div style={{margin: "20px 50px 50px 50px", padding: 20, display: "flex", flexDirection: "row",background: theme.palette.mode === "dark" ? "#FFFFFF10" : "#00000008"}}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                    <Typography variant="h6" fontSize={40} style={{ marginBottom: 40, marginLeft: 50 }} fontWeight={"bold"}>Quadrinhos Lidos</Typography>
                    <div style={{marginLeft: -150}}>
                        <PieChart                        
                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                    fill: 'white',
                                },
                            }}
                            series={seriesComics}
                            height={300}
                            width={800}
                        />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", flex: 1}}>
                    <img style={{objectFit: "contain", maxHeight: 400}} src={ChartEnciclo} alt={"Enciclo contando"}/>
                </div>
            </div>
            <div style={{margin: "20px 50px 50px 50px", padding: 20, display: "flex", flexDirection: "row",background: theme.palette.mode === "dark" ? "#FFFFFF10" : "#00000008", flex: 1}}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                    <Typography variant="h6" fontSize={40} style={{ marginBottom: 40, marginLeft: 50 }} fontWeight={"bold"}>Autores</Typography>
                    <div style={{marginLeft: -100}}>
                        <PieChart
                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                    fill: 'white',
                                },
                            }}
                            series={seriesGenAuthors}
                            height={300}
                            width={600}
                        />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", flex: 1}}>
                    <div style={{marginLeft: -100, marginBottom: 50}}>
                        <PieChart
                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                    fill: 'white',
                                },
                            }}
                            series={[{
                                arcLabel: (item) => `${item.value}`,
                                arcLabelMinAngle: 20,
                                data: etnAuthors.map((a) => {return {...a, color: colorsEtn[a.id]}})
                            }]}
                            height={200}
                            width={600}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(
    state => {
        return {
            readedComics: getReadedComics(state),
            genAuthors: getGenAuthors(state),
            etnAuthors: getEtnAuthors(state)
        };
    },
    {}
)(ChartsPage);
