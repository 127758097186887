import React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import XIcon from '@mui/icons-material/X';
import { useTheme } from '@mui/material/styles';

//Redux
import {
    getAuthorById,
} from "../../_reducers/authors";
import { connect } from "react-redux"

const ComicCardAuthor = ({ author, link_compra, i }) => {
    const theme = useTheme();

    return (<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flex: 1, width: "100%", alignItems:"center", background: theme.palette.mode === "dark" ? "#FFFFFF10" : "#00000008", padding: 10, marginTop: i > 0 ? 10 : -9}}>
        <Typography variant="h6" fontSize={14} fontWeight={"bold"} style={{ flex: 1 }}>
            {author.name}
        </Typography>
        <div style={{}}>
            {author.insta && <IconButton aria-label="instagram" href={author.insta} target="_blank">
                <InstagramIcon />
            </IconButton>}
            {author.twitter && <IconButton aria-label="x" href={author.twitter} target="_blank">
                <XIcon />
            </IconButton>}
            {link_compra && <IconButton aria-label="link_compra" href={link_compra} target="_blank">
                <ShoppingCartIcon />
            </IconButton>}
        </div>
    </div>)
}

export default connect(
    (state, prop) => {
        return {
            author: getAuthorById(state, prop.author_id),
        };
    },
    {}
)(ComicCardAuthor);
