import { configureStore } from '@reduxjs/toolkit'

import loggerMiddleware from './middleware/logger'
import rootReducer from './_reducers'

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().prepend(loggerMiddleware),
    preloadedState,
  })

  return store
}