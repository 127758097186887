import React from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';


export const MenuBar = ({ menuButtons, changeActiveMenu }) => {
    const renderMenuButton = (isActive, title, id) => {
        return <Button color={"inherit"} fontFamily={"system-ui"} style={{ margin: "0px 15px"}} key={id} size='large' variant={isActive ? 'text' : 'text'} onClick={() => {
            changeActiveMenu(id)
        }}>
            <Typography fontFamily={"inherit"} fontWeight={isActive ? 'bold' : 'normal'}>
                {title}
            </Typography>
        </Button>
    }

    return (<div>
        {menuButtons.map((button) => renderMenuButton(button.active, button.title, button.id))}
    </div>
    )
}
