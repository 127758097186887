import { combineReducers } from "redux";
import counters, * as fromCounters from "./counters";
import readedComics, * as fromReadedComics from "./readedComics";
import genAuthors, * as fromGenAuthors from "./genAuthors";
import etnAuthors, * as fromEtnAuthors from "./etnAuthors";

const getLocalState = state => state.counters;

export default combineReducers({
  counters,
  readedComics,
  genAuthors,
  etnAuthors
});

export const getAllCounters = state =>
  fromCounters.getCounters(getLocalState(state));


export const getReadedComics= state =>
  fromReadedComics.getReadedComics(getLocalState(state));


export const getGenAuthors= state =>
  fromGenAuthors.getGenAuthors(getLocalState(state));


export const getEtnAuthors= state =>
  fromEtnAuthors.getEtnAuthors(getLocalState(state));
