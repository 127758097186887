import { combineReducers } from "redux";
import ids, * as fromIds from "./ids";
import byId, * as fromById from "./byId";

const getLocalState = state => state.authors;

export default combineReducers({
  ids,
  byId
});

export const getAuthorById = (state, id) =>
  fromById.getAuthor(getLocalState(state), id);

export const getAuthors = state => {
const localState = getLocalState(state);
const ids = fromIds.getIds(localState);
if (!ids) {
  return []
}
return ids.map(id => fromById.getAuthor(localState, id));
};
