import { FETCH_COMICS_SUCCESS } from "../../_actions/comics";

const readedComics = (state = [], action) => {
    switch (action.type) {
        case FETCH_COMICS_SUCCESS: {
            const readedComics = getReadedComicsData(action.response.comicsList);

            return readedComics;
        }
        default:
            return state;
    }
};

export const getReadedComics = (state) => state.readedComics;

export default readedComics;

const getReadedComicsData = (comicsList) => {
    const finishedComics = comicsList.filter((comic) => {
        return comic.porcentagem_leitura === 100
    })

    const ongoingComics = comicsList.filter((comic) => {
        return comic.porcentagem_leitura < 100
    })

    return [
        { id: 0, value: comicsList.length, label: 'Total de quadrinhos' },
        { id: 1, value: ongoingComics.length, label: 'Quadrinhos para terminar' },
        { id: 2, value: finishedComics.length, label: 'Quadrinhos 100% finalizados' },
    ];
}