import { combineReducers } from "redux";
import counters from "./counters";
import comics from "./comics";
import galleries from "./galleries";
import authors from "./authors";

const appReducer = combineReducers({
  comics,
  counters,
  galleries,
  authors
});

const rootReducer = (state = {}, action) => {
  return appReducer(state, action);
};

export default rootReducer;
