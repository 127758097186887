import { FETCH_AUTHORS_SUCCESS } from "../../_actions/comics";

const ids = (state = [], action) => {
  switch (action.type) {
    case FETCH_AUTHORS_SUCCESS:
      return  action.response.result;
    default:
      return state;
  }
};

export const getIds = state => state.ids;

export default ids;
