import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { convertDate } from "../utils/formatDate"
import Divider from '@mui/material/Divider';
import { colorConverter } from "../utils/colorConverter"
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Paper from '@mui/material/Paper';
import ComicCardAuthor from './ComicsPage/ComicCardAuthor';
import { Dialog } from '@mui/material';

import EncicloLoading from "../assets/LOADING.png"

//Redux
import { fetchComics } from "../_actions/comics";
import { getComic } from "../_reducers/comics/byId";
import { getIsFetching } from "../_reducers/comics";
import { connect } from "react-redux"
import { useRef, useState, useEffect } from 'react';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const RenderCounters = (contadores) => {
  return (
    <Stack direction="row" spacing={1} style={{ marginLeft: -10, flexWrap: "wrap" }}>
      {contadores.map(contador => {
        return <Chip key={contador} label={contador} style={{ marginLeft: 5, marginBottom: 10 }} />
      })}
    </Stack>
  );
}

const ComicModal = ({ open, handleClose, comic, fetchComics, isFetching }) => {
  const [errorImage, setErrorImage] = useState(false)
  const prevAmount = usePrevious({ isFetching });
  const img = new Image();
  img.src = comic.image;

  useEffect(() => {
    if (prevAmount?.isFetching === true && isFetching === false) {
      setErrorImage(false)
    }
  }, [prevAmount?.isFetching, isFetching])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <Paper style={{
        width: 600,
        border: '2px solid #000',
        boxShadow: 24,
      }}>
        <div>
          <img
            component="img"
            height={200}
            width={"100%"}
            style={{ objectFit: "cover" }}
            src={errorImage ? EncicloLoading : img.src}
            alt={comic.name}
            onError={e => {
              setErrorImage(true)
              if (!isFetching) {
                fetchComics()
              }
            }}
          />
        </div>
        {errorImage && <LinearProgress />}
        <div style={{ margin: 20 }}>
          <Typography id="modal-modal-title" variant="h4">
            {comic.name}
          </Typography>
          <Typography id="modal-modal-description" style={{ marginBottom: 10 }}>
            {`Lido em ${convertDate(comic.data_leitura)}`}
          </Typography>
          <Typography id="modal-modal-description">
            <Stack direction="row" spacing={1} style={{ flexWrap: "wrap" }}>
              {comic.genero.map(gen => {
                return <Chip key={gen.name} label={gen.name} color={colorConverter(gen.color)} style={{ opacity: 0.9, marginLeft: 5, marginBottom: 10 }} />
              })}
            </Stack>
          </Typography>

          <div style={{ flex: 1 }}>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Typography variant="body2" fontSize={12} gutterBottom>
              Capítulos lidos: <b>{comic.caps_lidos}/{comic.total_caps}</b>
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" color={comic.porcentagem_leitura === 100 ? "success" : comic.porcentagem_leitura > 30 ? "warning" : "error"} value={comic.porcentagem_leitura} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography fontSize={12} variant="body2">{`${Math.round(
                  comic.porcentagem_leitura,
                )}%`}</Typography>
              </Box>
            </Box>

            <div style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: 10
            }}>
              <Button size='small' endIcon={<OpenInNewIcon />}
                onClick={() => {
                  if (comic.link_leitura) {
                    window.open(comic.link_leitura, '_blank');
                  }
                }}>
                Leia agora
              </Button>
            </div>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            {comic.contadores.length > 0 &&
              <div>
                <Typography variant="body2" fontSize={12} gutterBottom>

                  Contadores <b>{`(${comic.contadores.length})`}</b>
                </Typography>
                {RenderCounters(comic.contadores)}
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              </div>}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              {comic.autores.map((author, i) => {
                return <ComicCardAuthor author_id={author} i={i} />
              })}
            </div>
          </div>
        </div>
      </Paper>
    </Dialog>
  );
}

export default connect(
  (state, prop) => {
    return {
      comic: getComic(state.comics, prop.comic_id),
      isFetching: getIsFetching(state)
    };
  },
  {
    fetchComics,
  }
)(ComicModal);
