import * as React from 'react';
import KofiBanner from './KofiBanner';
import TwitchBanner from './TwitchBanner';
import DiscordBanner from './DiscordBanner';
import useWindowDimensions from '../../utils/windowDimension';
import StaffImage from "../../assets/STAFFB.png"

const AboutPage = () => {
    const { height } = useWindowDimensions()

    return (
        <div style={{ display: "flex", flex: 1, flexDirection: "column", minHeight: height - 290, marginBottom: -50 }}>
            <div style={{ flex: 1, display: "flex" }}>
                <div style={{ flex: 1, display: "flex", justifyContent: "end" }}>
                    <img style={{ maxHeight: height - 290 }} src={StaffImage} alt={"LQN Enciclopédia"} />
                </div>
                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <TwitchBanner />
                    <DiscordBanner/>
                    <KofiBanner />
                </div>
            </div>
        </div>
    );
}

export default AboutPage;