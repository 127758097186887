import { FETCH_COMICS_SUCCESS } from "../../_actions/comics";

const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COMICS_SUCCESS:
      return {
        ...state,
        ...action.response.entities.comic
      };
    default:
      return state;
  }
};

export const getComic = (state, id) => {
  return state.byId[id]
};

export default byId;
